import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ImageRow } from "../projects/roboto"
import mel from "../images/Melissa1.jpg"
import mel2 from "../images/Melissa2.jpg"
import mel3 from "../images/Melissa3.jpg"
import mel4 from "../images/Melissa4.jpg"

const Index = () => (
  <Layout>
    <SEO title="About Me" />
    <div style={{ padding: "0 2rem" }}>
      <p>
        <br />I am currently a mechanical design engineer at ASML, developing
        mechanical components for semiconductor lithography machines. I love
        designing and creating tangible products, robots, and other new
        technologies that can further scientific advancement and improve the
        lives of others. I graduated from MIT in June 2021 with a double major
        in Mechanical Engineering and Music. I have worked as an undergraduate
        research assistant in various MIT labs in the biomedical, robotics, and
        aerospace fields. Aside from engineering, I enjoy hiking, traveling,
        photography, as well as composing and playing the piano and accordion.
        At MIT, I performed in a 50-minute solo piano recital and played in a
        trio for 3 years.
      </p>
      <ImageRow images={[mel, mel2, mel3, mel4]} height="12" />
    </div>
  </Layout>
)

export default Index

//render(<Index />, document.getElementById("index"))
